.page-home_container {
    padding-bottom: 150px;
}

.page-home-jumbotron {
    min-height: calc(100vh - 100px);
    background: url("../assets/banner/b1.jpg") no-repeat center;
    background-size: cover;

    .page-home-jumbotron_wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 50px 0 100px 60px;
        @media screen and (max-width: 480px) {
            padding: 50px 10px 0px;
            text-align: center;
        }
    }

    .page-home-jumbotron_body {
        max-width: 700px;
    }

    .page-home-jumbotron_title {
        font-size: 80px;
        font-weight: 700;
        line-height: 1.2em;
        color: black;
        filter: drop-shadow(1px 1px 0px #fff);
        @media screen and (max-width: 480px) {
            font-size: 2.5em;
            text-align: center;
        }
    }

    .page-home-jumbotron_description {
        padding: 40px 0;
        font-size: 1.6em;
        line-height: 1.5em;
        font-weight: 700;
        color: white;
        filter: drop-shadow(1px 1px 0px #2a2a2a);
    }
}

.page-home-quickpick {
    justify-content: center;
    margin-top: -100px;
    padding-bottom: 80px;

    .page-home-quickpick-item {
        margin: 0 10px 20px;
        width: 350px;
        height: 350px;
        background-color: white;
        box-shadow: 0px 0px 50px 0px rgb(0 0 0 / 6%);
        text-align: center;
    }
    .page-home-quickpick-item_wrapper {
        padding: 50px 0;
    }

    .page-home-quickpick-item_img {
        padding-bottom: 30px;
    }
    .page-home-quickpick-item_name {
        padding-bottom: 25px;
        color: black;
    }
    .page-home-quickpick-item_description {
        padding: 0 30px;
    }
}

.page-home-intro {
    img {
        max-width: 100%;
    }
    .page-home-intro_info {
        padding-bottom: 40px;
    }

    .page-home-intro_title {
        padding-bottom: 30px;
        color: black;
    }

    .page-home-intro_description {
        padding-bottom: 30px;
    }
}

.page-home-banner {
    padding: 150px 0;
    img {
        width: 100%;
    }
}

.page-home-services {
    padding: 100px 0 150px 0;
    .page-home-services-header {
        padding-bottom: 50px;
        text-align: center;
    }

    .page-home-services-header .page-home-services-header_title {
        margin-bottom: 10px;
        color: black;
    }

    .page-home-services-header .page-home-services-header_description {
        color: black;
    }

    .page-home-services-body {
        margin: 0 -10px;
    }

    .page-home-services-body-item {
        padding: 0 10px 20px;
    }

    .page-home-services-body-item_wrapper {
        min-height: 510px;
        border-bottom: 2px solid black;
        background-color: white;
        box-shadow: 0px 0px 50px 0px rgb(0 0 0 / 6%);
        padding: 35px;
        @media screen and (max-width: 480px) {
            padding: 35px 20px;
        }
    }

    .page-home-services-body-item_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        height: 100%;
    }

    .page-home-services-body .page-home-services-body-item img.thumb {
        margin-bottom: 20px;
    }

    .page-home-services-body-item_label {
        color: black;
    }
    .page-home-services-body .page-home-services-body-item .page-home-services-body-item_text {
        padding: 20px 0;
    }

    .page-home-services-body .page-home-services-body-item .page-home-services-body-item_preview {
        margin-top: 30px;
        img {
            width: 100%;
        }
    }
}

.page-home-gallery {
    img {
        max-width: 100%;
    }
    .page-home-gallery-thumb {
        padding-bottom: 40px;
    }
    .page-home-gallery-text {
        color: black;
    }
    .page-home-gallery-description {
        padding: 40px 0;
    }

    .page-home-gallery-list {
        padding-bottom: 40px;
        line-height: 1.7em;
    }
}
