.ui-button {
    display: inline-block;
    padding: 23px 30px;
    background: black;
    font-weight: 700;
    color: white;
    text-transform: capitalize;
    letter-spacing: 0.2em;
    cursor: pointer;
    transition: 0.2s ease all;

    &._primary {
        padding: 15px 30px;
    }

    &._outline {
        border: 1px solid #ebebeb;
        background-color: white;
        color: black;
        &:hover {
            filter: invert(100%);
        }
    }
    &._secondary {
        background: #fff;
        color: black;
        &:hover {
            filter: invert(100%);
        }
    }
}

.ui-text {
    &._h3 {
        font-size: 2em;
        font-weight: 900;
        line-height: 1.3em;
        color: inherit;
    }

    &._h2 {
        font-size: 1.4em;
        font-weight: 700;
        line-height: 1.2em;
        color: inherit;
    }

    &._h1 {
        font-size: 1.1em;
        font-weight: 700;
        line-height: 1.1em;
        color: inherit;
    }
}
