.page-about-container {
    padding: 100px 0;
}
.page-about-stats-container {
    background: black;
    color: #fff;

    .page-about-stats {
        padding: 100px 0;
        justify-content: center;
    }

    .page-about-stats_count {
        margin-bottom: 20px;
        text-align: center;
    }
}
.page-about-clients {
    text-align: center;
    padding: 70px 40px;
    color: black;

    .columns {
        justify-content: center;
        align-items: center;
    }
    .page-about-clients-list {
        padding-top: 50px;
    }
    .page-about-clients-item {
        img {
            max-width: 100%;
            max-height: 60px;
        }
        .invert {
        }
    }
}

.page-about-intro {
    padding-bottom: 150px;
    text-align: center;
    color: black;

    .page-about-intro-wrap {
        justify-content: center;
    }
    .page-about-intro-description {
        padding-top: 30px;
        color: var(--color-secondary);
    }
}
