.page-services-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    background: url("../assets/banner/bg2.jpg") center no-repeat;
    background-size: cover;
    color: white;
    .text {
        filter: drop-shadow(1px 1px 0px #2a2a2a);
    }
}

.page-services {
    padding: 100px 0;
    .page-services-header {
        color: black;
        text-align: center;
    }

    .page-services-list-item-wrapper {
        display: flex;
        padding: 50px;
    }

    .page-services-list-item_img {
        padding-right: 30px;
        color: var(--color-secondary);
        font-size: 2.1em;
    }

    .page-services-list-item-details_name {
        margin-bottom: 20px;
        color: var(--color-secondary);
    }

    .page-services-list-item-details_description {
        color: #696969;
    }
}
